<template>
  <div class="pbf grid_width">
 
    <GeneralSearchTags  
      :class="{gloading: is_loading}"     
      :tags="props.intags" 
      :tagsselected="tagsselected" 
      :tagsdefault="props.intags" 
      :tagscount="explore_data?.items_tag"      
      @choose="chooseTags"
      @unchoose="unchooseTags"
      />

    <div class="sortb">
      <UiSort 
        @choose="chooseSort"
        :val="sort"    
        :options="[
          {id:'update', name: $t('universal_sort_last_update')},      
          {id:'score', name: $t('universal_sort_grow_score')},      
          {id:'likes', name: $t('universal_sort_likes2')},      
          {id:'create', name: $t('universal_sort_creation_date')},      
        ]"
        />
    </div>

      
    <SkExploreDiaries
      v-if="is_loading && !explore_data?.items?.length"
      />
      
    <div v-else-if="explore_data?.items?.length" class="report_boxs reports_grid">      
      <GeneralDiary       
        v-for="(opt,ind) in explore_data.items"    
        :key="ind"    
        :data="opt"      
        />          
      <ClientOnly>        
        <UiInfinityScroll 
          v-if="explore_data?.items?.length >= limit"
          :is-loading="is_loading"
          ref="elInfinityScroll"/>
      </ClientOnly>
    </div>

    <Empty
      v-else-if="!explore_data?.items?.length"
      />

    <SkExploreDiaries
      v-else
      />
  

  </div>
</template>

<script setup lang="ts">
 
import { getTagsKeyword, getTagsKeys, getTagsSimple  } from '@/types/other'
import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'


const start = ref(0)
const limit = ref(20)
const isEnded = ref(false)
const {$api, $ga} = useNuxtApp()
const elInfinityScroll = ref(null)
const sort = ref('update')
const tagsselected = ref([])
const tagspermanent = ref([]);

const props = defineProps({
  inurl: {
    type: String
  },
  intags: {
    type: Object
  },
  owner: {
    type: Boolean,
    default: false
  },
  brandId: {
    type: Number,
    required: false
  },
  productId: {
    type: Number,
    required: false
  },
  growerId: {
    type: Number,
    required: false
  },
  strainId: {
    type: Number,
    required: false
  }
});


if(props.brandId){
  tagspermanent.value.push('br:' + props.brandId);
}

if(props.productId){
  tagspermanent.value.push('pr:' + props.productId);
}

if(props.growerId){
  tagspermanent.value.push('us:' + props.growerId);
}

if(props.strainId){
  tagspermanent.value.push('st:' + props.strainId);
}


const clearPagination = function(){     
  start.value = 0;  
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
  clearPagination();  
}
 

const unchooseTags = function(selected){  
  tagsselected.value = tagsselected.value.filter((item) => item != selected);
}        
const chooseTags = function(selected){          
  tagsselected.value.push(selected);      
  clearPagination();    
  $ga.eventGA('explore', 'tag', getTagsSimple(props.intags, tagsselected.value, tagspermanent.value));
}


const loadData = async function() {  
  const response = await $api.getDiaries({
    start: start.value,
    limit: limit.value,
    owner: props.owner ? 1 : 0,
    q: getTagsKeyword(props.intags, tagsselected.value, tagspermanent.value),       
    sortable: sort.value,
    tagsall: getTagsKeys(props.intags),
    tags: getTagsSimple(props.intags, tagsselected.value, tagspermanent.value)
  });
  return response;  
}



const { pending: is_loading,  data: explore_data } = await useLazyAsyncData('explore_data', async () => await loadData())


watchArray([start, sort, tagsselected], async ([new_start, new_sort, new_tagsselected], added, removed) => {
  
  if(new_start === 0){
    explore_data.value.items = [];
    isEnded.value = false;
  }
  
  is_loading.value = true;
  var dt = await loadData();

    
  if(dt.length == 0)
    isEnded.value = true;  

  explore_data.value.items = [...explore_data.value.items, ...dt.items]
  if(new_start === 0){
    explore_data.value.items_tag = dt.items_tag
  }
  is_loading.value = false;

  // $adv.apply();

}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)


 
</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}

.sortb{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

</style>
